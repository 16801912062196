<template>
  <div class>
    <div class="editorx_body">
      <div
        id="codex-editor"
        class
      />
    </div>
    <b-button
      style="margin-left: 30%;"
      type="button"
      name="button"
      @click="save()"
    >
      save
    </b-button>
    <div class="editorx_body">
      <pre>{{ blocs }}</pre>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: { BButton },
  props: {
    blocs: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.myEditor()
  },
  methods: {
    save() {
      window.editor.save().then(savedData => {
        console.log(savedData)
        this.$emit('change', savedData)
        // self.handleChange(savedData)
      })
    },
    myEditor() {
      // window.editor = new EditorJS({
      //   data: this.blocs,
      //   holder: 'codex-editor',
      //   autofocus: true,
      //   /**
      //    * This Tool will be used as default
      //    */
      //   initialBlock: 'paragraph',
      //   tools: {
      //     underline: Underline,
      //     // AnyButton: {
      //     //   class: AnyButton,
      //     //   inlineToolbar: false,
      //     // },
      //     header: {
      //       class: Header,
      //       shortcut: 'CMD+SHIFT+H',
      //     },
      //     list: {
      //       class: List,
      //     },
      //     paragraph: {
      //       class: Paragraph,
      //       config: {
      //         placeholder: '.',
      //       },
      //     },
      //     image: {
      //       class: Image,
      //       config: {
      //         /**
      //          * Custom uploader
      //          */
      //         uploader: {
      //           /**
      //            * Upload file to the server and return an uploaded image data
      //            * @param {File} file - file selected from the device or pasted by drag-n-drop
      //            * @return {Promise.<{success, file: {url}}>}
      //            */
      //           uploadByFile(file) {
      //             console.log(window)
      //             // your own uploading logic here
      //             const cloudinary = 'https://api.cloudinary.com/v1_1/kipli-cdn/upload'
      //             // const request = {
      //             //   url: cloudinary,
      //             //   method: 'POST',
      //             //   data: file,
      //             // }
      //             const formData = new FormData()
      //             formData.append('upload_preset', 'pz75gd5c')
      //             formData.append('file', file)
      //             // eslint-disable-next-line arrow-body-style
      //             return axios.post(cloudinary, formData).then(({ data }) => {
      //               return {
      //                 success: 1,
      //                 file: {
      //                   url: data.url,
      //                   // any other image data you want to store, such as width, height, color, extension, etc
      //                 },
      //               }
      //             })
      //           },
      //         },
      //       },
      //     },
      //     unsplash: {
      //       class: InlineImage,
      //       inlineToolbar: true,
      //       config: {
      //         embed: {
      //           display: true,
      //         },
      //         unsplash: {
      //           appName: 'Site',
      //           clientId: 'aG09NEMR7MIWtMXwewlI816f4rofYnudbEbEYUc4AdQ',
      //         },
      //       },
      //     },
      //   },
      //   onReady() {
      //     console.log('ready')
      //   },
      //   onChange() {
      //     console.log('change')
      //     // editor.save().then((savedData) => {
      //     //   //console.log(savedData)
      //     //   this.value = savedData
      //     // });
      //   },
      // })
    },
  },
}
</script>

<style lang="css" scoped >
.editorx_body {
  /* width: 62%;
  margin-left: 15%; */
  width: 60%;
  margin-left: 20%;
  border: 2px solid #f1f3f5;
  box-sizing: border-box;
}

.ce-block--focused {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 0.5438550420168067) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
</style>
