<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="guide.title"
      lazy
      static
    >
      <div v-if="guide">
        <!-- <v-jsoneditor
          v-model="blocs"
          :options="options"
          height="600px"
          @error="errorJson"
        /> -->
        <quill-editor-snow style="display: none;" />
        <app-collapse>
          <app-collapse-item title="Réglages générales">
            <b-row>
              <b-col :md="6">
                <p>Titre:</p>
                <b-form-input
                  v-model="guide.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Slug:</p>
                <b-form-input
                  v-model="guide.slug"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Meta Description:</p>
                <b-form-input
                  v-model="guide.meta"
                  class="mr-4"
                  style="width: 100%"
                />
                <img
                  v-if="guide.img"
                  :src="guide.img"
                  style="width: 200px"
                >
              </b-col>
              <b-col :md="6">
                <p>Image:</p>
                <b-form-input
                  v-model="guide.img"
                  class="mr-4"
                  style="width: 100%"
                />
                <img
                  v-if="guide.img"
                  :src="guide.img"
                  style="width: 200px"
                >
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Blocs">
            <b-row
              class="my-2"
            >
              <b-col
                cols="12"
                class="primary d-flex"
              >
                <editor style="width: 100%" :blocs="guide.blocs"  @change="e => guide.blocs = e"/>

                <quill-editor v-model="guide.content" style="width: 100%" />
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-row
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="updateValue()"
            >
              <span class="text-nowrap">Enregistrer</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card title="Vous pouvez modifier les guides de blog directement ici.">
      <b-row class="match-height">
        <!-- <b-col
          md="3"
          lg="3"
          class="mb-4"
        >
          <b-button
            variant="primary"
            @click="createGuide()"
          >
            Créer un nouvel guide
          </b-button>
        </b-col> -->
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          v-for="pdct in guides"
          :key="pdct.id"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            v-if="pdct"
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.img"
              class="item-img text-center"
            >
              <b-img
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.img"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <h4 class="item-name">
                {{ pdct.title }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ guide.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- guide Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier l'guide</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import Editor from '@/components/encaissement/Editor.vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import QuillEditor from '@/@core/components/quill-editor/QuillEditor.vue'

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    QuillEditorSnow,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    quillEditor,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    QuillEditor,
    Editor,
  },
  data() {
    return {
      guides: [],
      guide: {},
      showModal: false,
      options: { mode: 'code' },
      blocs: [],
      productSelect: [],
      productId: null,
      firstContent: '',
      country: 'DE',
      countryOptions: ['ES', 'FR', 'DE', 'IT'],
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  watch: {
    country: {
      deep: true,
      handler: 'getguides',
    },
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      await this.getguides()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    test() {
      console.log(this.content.content)
    },
    async getguides() {
      try {
        const { guides } = await this.$http.get(`/admin/guide/?country=${this.country}`)
        this.guides = guides
      } catch (err) {
        console.log(err)
      }
    },
    async createGuide() {
      const { products } = await this.$http.get(`/admin/blog/list-product/?country=${this.country}`)
      this.productSelect = products
      this.guide = {
        blocs: [],
        firstContent: '',
        productId: null,
        country: this.country,
        isNew: true,
        slug: '',
        title: '',
        image: {
          url: '',
          alt: '',
        },
        category: '',
      }
      this.blocs = []
      this.firstContent = ''
      this.productId = null
      this.showModal = true
    },
    async openModal(guide) {
      this.showModal = true
      // const { products } = await this.$http.get(`/admin/blog/list-product/?country=${guide?.country}`)
      // this.productSelect = products
      this.guide = guide
    },
    async updateValue() {
      try {
        if (this.guide.isNew) {
          await this.$http.post('admin/guide/', { guide: this.guide })
        } else {
          await this.$http.put(`admin/guide/${this.guide._id}`, { guide: this.guide })
        }
        await this.getguides()
        this.showModal = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    errorJson(err) {
      console.log(err)
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
